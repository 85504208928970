// @flow

export type ItemShoppingCart = {| id: string, value: string | boolean | null |}

export default class ConfigurationShoppingCart {
  +configurationId: ?string
  +programId: string
  +lotId: string
  +itemsShoppingCart: ItemShoppingCart[]
  +createdAt: ?Date
  +ambianceId: string

  constructor(
    configurationId: ?string,
    programId: string,
    lotId: string,
    items: ItemShoppingCart[],
    createdAt: ?Date = null,
    ambianceId: string,
  ) {
    if (!programId) {
      throw new Error('The programId is mandatory')
    }

    if (!lotId) {
      throw new Error('The lotId is mandatory')
    }

    this.configurationId = configurationId || null
    this.programId = programId
    this.lotId = lotId
    this.itemsShoppingCart = items
    this.createdAt = createdAt || null
    this.ambianceId = ambianceId

    Object.freeze(this)
  }

  getAmbanceId(): string {
    return this.ambianceId
  }

  getItems(): ItemShoppingCart[] {
    return this.itemsShoppingCart
  }

  toArray(): ItemShoppingCart[] {
    return [...this.itemsShoppingCart]
  }
}
