// @flow

import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import Smilling3ditBigSvg from '../Icons/Smilling3ditBigSvg'
import { Colors } from '../Colors'
import Bounce from '../BouncingAnimation'
import { RobotoNormal } from '../Typography/Fonts'

const shineMeIamFamous = keyframes`
  from {
    color: rgb(255, 255, 255, 0.2);
  }

  to {
    color: rgb(255, 255, 255, 0.5);
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Description = styled.p`
  color: ${Colors.greyBold};
  font-size: 14px;

  margin-top: ${({ loadingProgress }) =>
    typeof loadingProgress === 'number' ? '10px' : '54px'};
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  line-height: 20px;
  color: rgb(255, 255, 255, 0.2);

  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-name: ${shineMeIamFamous};
`

const LoadingProgressWrapper = styled.div`
  margin-top: 40px;
  position: relative;
  width: 220px;
  height: 4px;
  border-radius: 4px;
  background-color: ${Colors.blackRegular};
`

const Filler = styled.div`
  height: 100%;
  width: ${({ loadingProgress }) => loadingProgress}%;
  transition: width 0.2s ease-in-out;
  border-radius: inherit;
  background-color: ${Colors.green};
`

export default ({
  message = 'Chargement',
  loadingProgress,
}: {|
  message?: string,
  loadingProgress?: number,
|}) => {
  return (
    <LoadingContainer>
      <Bounce>
        <Smilling3ditBigSvg />
      </Bounce>
      {typeof loadingProgress === 'number' ? (
        <LoadingProgressWrapper>
          <Filler loadingProgress={loadingProgress} />
        </LoadingProgressWrapper>
      ) : null}
      <Description loadingProgress={loadingProgress}>{message}</Description>
    </LoadingContainer>
  )
}
