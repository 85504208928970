// @flow

import styled from 'styled-components'
import { BaseModalBackground } from 'styled-react-modal'
import { Colors } from '../Colors'

const SlidingDownOpaqueBackground = styled(BaseModalBackground)`
  width: 100vw;
  height: 100vh;

  color: ${Colors.greyRegular};
  background-color: #333333;
  z-index: 2000;

  top: ${props => (props.visible ? '0' : '-100vh')};
  transition: top ease-in-out 300ms;
`

export default SlidingDownOpaqueBackground
