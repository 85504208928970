// @flow

import * as React from 'react'

export default ({ innerRef }: {| innerRef?: React.Ref<*> |}) => (
  <svg
    ref={innerRef}
    width="128"
    height="128"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-98 -98)">
        <g id="3dit" transform="translate(98 98)">
          <path
            id="3dit-face"
            stroke="#8E8E8E"
            strokeWidth="2"
            d="M30,1 C5.59828475,1 1,5.59828475 1,30 C1,54.4000184 5.59958783,59 30,59 C54.3987153,59 59,54.3987153 59,30 C59,5.59958783 54.4000184,1 30,1 Z"
          />
          <path
            id="3dit-mouth"
            fillRule="nonzero"
            stroke="#3BC492"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21.9072592,41.6151939 C24.2565732,43.1273201 27.025626,44 29.9904267,44 C34.8470826,44 39.1784707,41.6582602 42,38"
          />
          <circle cx="39" cy="21" r="3" fill="#8E8E8E" />
          <circle cx="21" cy="21" r="3" fill="#8E8E8E" />
        </g>
      </g>
    </g>
  </svg>
)
