// @flow

import styled, { keyframes } from 'styled-components'

const bouncing = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 20px, 0);
  }
`

const Bounce = styled.div`
  animation: ${bouncing} 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

export default Bounce
