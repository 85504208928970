// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import { programPreviewHomePageRoute } from './urls'
import PageNotFoundSvg from './Guidelines/Icons/PageNotFoundSvg'
import styled from 'styled-components'
import { Colors } from './Guidelines/Colors'
import { RobotoNormal } from './Guidelines/Typography/Fonts'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`

const TextContainer = styled.div`
  margin-top: 25px;
  max-width: 256px;
  text-align: center;
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  text-align: center;
  color: ${Colors.white};
`

const TextDescription = styled.span`
  opacity: 0.3;
`

const BackToHome = styled(Link)`
  color: ${Colors.white};
  transition: opacity 0.25s ease-in-out;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`

export default () => (
  <Wrapper>
    <PageNotFoundSvg />
    <TextContainer>
      <TextDescription>
        Oups, cette page n’existe pas ! Merci de{' '}
      </TextDescription>
      <BackToHome to={programPreviewHomePageRoute()}>
        retourner a l’accueil.
      </BackToHome>
    </TextContainer>
  </Wrapper>
)
