// @flow

import * as React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { initErrorReportingAgent } from './ErrorReporting'
import MainApplication from './MainApplication'
import PantonFontProvider from './Guidelines/Typography/PantonFontProvider'
import history from './history'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN
if (!sentryDSN) {
  throw new Error('The sentry dsn environment variable is missing')
}
const buildEnvironment = process.env.REACT_APP_BUILD_ENVIRONMENT
if (!buildEnvironment) {
  throw new Error('The build environment environment variable is missing')
}
const applicationName = process.env.REACT_APP_NAME
if (!applicationName) {
  throw new Error('The application name environment variable is missing')
}
const applicationVersion = process.env.REACT_APP_VERSION
if (!applicationVersion) {
  throw new Error('The application version environment variable is missing')
}

initErrorReportingAgent(sentryDSN, buildEnvironment, applicationVersion)

const root = document.getElementById('root')
if (!root) {
  throw new Error('Unable to find the root element to mount the application')
}

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
if (!apiEndpoint) {
  throw new Error('The `REACT_APP_API_ENDPOINT` environment variable is empty')
}

const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID
if (!oauthClientId) {
  throw new Error(
    'The `REACT_APP_OAUTH_CLIENT_ID` environment variable is empty',
  )
}

const oauthClientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET
if (!oauthClientSecret) {
  throw new Error(
    'The `REACT_APP_OAUTH_CLIENT_SECRET` environment variable is empty',
  )
}

ReactDOM.render(
  <React.Fragment>
    <PantonFontProvider />
    <Router history={history}>
      <MainApplication
        apiEndpoint={apiEndpoint}
        oauthClientId={oauthClientId}
        oauthClientSecret={oauthClientSecret}
      />
    </Router>
  </React.Fragment>,
  root,
)
