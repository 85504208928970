// @flow

import * as React from 'react'
import styled from 'styled-components'
import Modal, { ModalProvider } from 'styled-react-modal'
import ModalBackground from './OpaqueModalBackground'
import CrossSvg from '../Icons/CrossSvg'

type Props = {|
  isOpen: boolean,
  closable?: boolean,
  modalBackground: React.AbstractComponent<*>,
  onClose: () => Promise<void> | void,
  children: React.Node,
|}

type State = {|
  isVisible: boolean,
|}

const ModalContainer = Modal.styled`
  width: 100%;
  height: 100%;
`

const ModalContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
`

export default class FullScreenModal extends React.Component<Props, State> {
  static defaultProps = {
    closable: true,
    modalBackground: ModalBackground,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      isVisible: !props.closable,
    }
  }

  componentDidMount() {
    const { isOpen } = this.props

    if (isOpen) {
      setTimeout(() => {
        this.setState({ isVisible: true })
      }, 10)
    }
  }

  onClose = (e: SyntheticEvent<HTMLButtonElement>) => {
    this.props.onClose()
  }

  afterOpen = () => {
    const waitForDomDelay = this.props.closable ? 50 : 0

    setTimeout(() => {
      this.setState({ isVisible: true })
    }, waitForDomDelay)
  }

  beforeClose = () => {
    const animationDelay = this.props.closable ? 500 : 0

    return new Promise<void>(resolve => {
      this.setState({ isVisible: false }, () => {
        setTimeout(() => resolve(), animationDelay)
      })
    })
  }

  render() {
    const { isOpen, closable, modalBackground, children } = this.props
    const { isVisible } = this.state

    return (
      <ModalProvider backgroundComponent={modalBackground}>
        <ModalContainer
          isOpen={isOpen}
          afterOpen={this.afterOpen}
          beforeClose={this.beforeClose}
          onBackgroundClick={() => {}}
          onEscapeKeydown={() => {}}
          backgroundProps={{ visible: isVisible }}
        >
          {closable ? (
            <CloseButton onClick={this.onClose}>
              <CrossSvg />
            </CloseButton>
          ) : null}

          <ModalContentContainer>{children}</ModalContentContainer>
        </ModalContainer>
      </ModalProvider>
    )
  }
}
