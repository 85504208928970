// @flow

import * as React from 'react'
import {
  Form,
  Input,
  SubmitButton,
  FormDescription,
  FieldError,
} from './../../Guidelines/Form'
import {
  ModalTitle,
  LayoutTwoColumns,
} from './../../Guidelines/Modal/ModalContent'
import Customer from '../Customer'
import { FakeLink } from './../../Guidelines/Typography/Link'

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onLogIn: (
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  onLogInSuccess: (customer: Customer) => void,
  switchToRegistrationMode: () => any,
  switchToRequestNewPasswordMode: () => any,
|}

type State = {|
  logInInProgress: boolean,
  errors: Array<{| field: ?string, message: string |}>,
|}

export default class CustomerLogInForm extends React.Component<Props, State> {
  +emailFieldRef: { current: null | React.ElementRef<'input'> }
  +passwordFieldRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.emailFieldRef = React.createRef()
    this.passwordFieldRef = React.createRef()

    this.state = { errors: [], logInInProgress: false }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onLogIn, onLogInSuccess } = this.props

    if (!this.emailFieldRef.current || !this.passwordFieldRef.current) {
      throw new Error('Unable to access to all the fields')
    }

    const email = this.emailFieldRef.current.value
    const password = this.passwordFieldRef.current.value

    this.setState({ logInInProgress: true, errors: [] }, async () => {
      const { customer, errors } = await onLogIn(email, password)

      if (errors.length > 0) {
        this.setState({
          logInInProgress: false,
          errors: errors,
        })

        return
      }

      if (!customer) {
        throw new Error('No errors in logIn process, but no customer neither')
      }

      this.setState({ logInInProgress: false, errors: [] }, () => {
        onLogInSuccess(customer)
      })
    })
  }

  getErrorByField(errors: OneErrorPayload[], field: ?string): ?OneErrorPayload {
    return this.state.errors.find(oneError =>
      field ? oneError.field === field : !oneError.field,
    )
  }

  render() {
    const {
      switchToRegistrationMode,
      switchToRequestNewPasswordMode,
    } = this.props
    const { errors, logInInProgress } = this.state

    const globalError = this.getErrorByField(errors)
    const emailError = this.getErrorByField(errors, 'email')
    const passwordError = this.getErrorByField(errors, 'password')

    return (
      <React.Fragment>
        <ModalTitle>Connexion</ModalTitle>

        <LayoutTwoColumns.Row>
          <LayoutTwoColumns.Column>
            <Form onSubmit={this.handleRegisterFormSubmit}>
              <Input
                ref={this.emailFieldRef}
                type="text"
                name="email"
                placeholder="Email"
                defaultValue={
                  process.env.NODE_ENV === 'development'
                    ? `customer@custhome.app`
                    : null
                }
                errored={!!emailError}
              />

              {emailError ? (
                <FieldError>{emailError.message}</FieldError>
              ) : null}

              <Input
                ref={this.passwordFieldRef}
                type="password"
                name="password"
                placeholder="Mot de passe"
                defaultValue={
                  process.env.NODE_ENV === 'development' ? '123456' : null
                }
                errored={!!passwordError}
              />

              {passwordError ? (
                <FieldError>{passwordError.message}</FieldError>
              ) : null}

              {globalError ? (
                <FieldError>{globalError.message}</FieldError>
              ) : null}

              <SubmitButton
                type="submit"
                disabled={logInInProgress}
                value="Valider"
              />
            </Form>
          </LayoutTwoColumns.Column>

          <LayoutTwoColumns.Column>
            <FormDescription>
              Merci de renseigner votre email et votre mot de passe pour vous
              connecter.
              <br />
              <br />
              <FakeLink onClick={() => switchToRequestNewPasswordMode()}>
                Mot de passe oublié ?
              </FakeLink>
              <br />
              <br />
              <FakeLink onClick={() => switchToRegistrationMode()}>
                Je n'ai pas de compte.
              </FakeLink>
            </FormDescription>
          </LayoutTwoColumns.Column>
        </LayoutTwoColumns.Row>
      </React.Fragment>
    )
  }
}
