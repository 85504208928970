// @flow

export const Colors = {
  white: '#fff',
  greyUltraLight: '#aaaaaa',
  greyLight: '#e5e5e5',
  greyRegular: '#c2c2c2',
  greyBold: '#8e8e8e',
  blackLight: '#555555',
  blackRegular: '#3d3d3d',
  blackBold: '#2e2e2e',
  blackUltraBold: '#222222',
  black: '#000',
  green: '#3bc492',
}
