// @flow

import * as React from 'react'
import styled from 'styled-components'
import Transition, { ENTERED } from 'react-transition-group/Transition'
import LoadingThatBounce from './LoadingThatBounce'

const ANIMATION_DURATION = 250

const FullScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${({ fadeIn }) =>
    fadeIn ? `transition: opacity ${ANIMATION_DURATION}ms ease-in-out` : null};
`

export default ({
  isVisible,
  message,
  fadeIn = true,
  children,
  loadingProgress,
}: {|
  isVisible: boolean,
  message?: string,
  fadeIn?: boolean,
  children?: React.Node,
  loadingProgress?: number,
|}) => (
  <Transition
    in={isVisible}
    timeout={fadeIn ? ANIMATION_DURATION : 0}
    unmountOnExit
  >
    {state => (
      <FullScreenWrapper isVisible={state === ENTERED} fadeIn={fadeIn}>
        {children || (
          <LoadingThatBounce
            message={message}
            loadingProgress={loadingProgress}
          />
        )}
      </FullScreenWrapper>
    )}
  </Transition>
)
