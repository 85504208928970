// @flow

import styled from 'styled-components'
import { Colors } from './Colors'
import { RobotoNormal } from './Typography/Fonts'
import { PrimaryButton } from './Button'

export const Input = styled.input`
  width: 100%;
  height: 48px;
  margin-top: 10px;
  padding: 14px 12px;
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  background-color: #2d2d2d;
  border-radius: 3px;
  border: solid 1px
    ${({ errored }) => (errored ? '#ef4872' : Colors.blackRegular)};
  color: ${Colors.greyRegular};
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);

  &::placeholder {
    color: ${Colors.greyBold};
  }

  &:first-child {
    margin-top: 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 4px 1000px #2d2d2d inset;
    -webkit-text-fill-color: ${Colors.greyRegular} !important;
  }

  &:focus {
    outline: none;
    border: 1px solid #888888;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  -webkit-appearance: none;

  width: 16px;
  height: 16px;
  margin: 1px 6px 1px 0;
  padding: 0;
  background-color: #2d2d2d;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px
    ${({ errored }) => (errored ? '#ef4872' : Colors.blackRegular)};
  border-radius: 3px;

  &:focus {
    border: 1px solid #888888;
  }

  &:checked {
    position: relative;
    background: none;
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    background-color: ${Colors.green};
    border-radius: 1px;
  }
`

export const Label = styled.label`
  width: fit-content;
  font-family: ${RobotoNormal.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoNormal.weight};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${Colors.greyRegular};
`

export const FieldError = styled.p`
  margin-top: 15px;
  margin-bottom: 10px;
  color: #ef4872;
`

export const CheckboxRow = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;

  & ${Checkbox} {
    float: left;
  }

  & ${Label} {
    margin-left: 24px;
    display: block;
  }
`

export const SubmitButton = styled(PrimaryButton).attrs({ type: 'submit' })`
  margin-top: 10px;
`

export const FormDescription = styled.p`
  margin: 0;
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  line-height: 20px;
  color: ${Colors.greyRegular};
`

export const Form = styled.form`
  & > ${Input}:nth-of-type(1) {
    margin-top: 0;
  }
`
