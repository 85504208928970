// @flow

export const programPreviewHomePageRoute = (floor: ?number) =>
  floor ? `/program?floor=${floor}` : '/program'

export const flatConfiguratorHomePageRoute = (lotId: string) => `/lot/${lotId}`

export const virtualTourPageRoute = (lotId: string) => `/lot/${lotId}`

export const customerAreaHomePageRoute = () => `/customer`

export const customerAreaLoginPageRoute = () => `/customer/login`

export const customerAreaRegisterPageRoute = () => `/customer/register`

export const customerAreaForgottenPasswordPageRoute = () =>
  `/customer/password/oops`

export const customerAreaResetPasswordPageRoute = () =>
  `/customer/password/reset`

export const customerAreaSavedConfigurationsPageRoute = () =>
  `/customer/configurations`

export const customerAreaSavedConfigurationDetailsPageRoute = (
  configurationId: string,
) => `/customer/configuration/${configurationId}`
