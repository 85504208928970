// @flow

import { type Axios } from 'axios'

export type Lot = {|
  area: number,
  available: boolean,
  code: string,
  floor: number,
  label: string,
  labelVirtualTour?: string,
  links: {
    virtual_tour?: string,
    plan: string,
  },
  lotId: string,
  priceIncludingTaxes20: number,
  typology: string,
|}

export const PACKS = Object.freeze({
  BUILDING_3D: 'maquette3d',
  BUILDING_3D_VIRTUAL_TOUR: 'maquette3d+visite_virtuelle',
  BUILDING_3D_CONFIGURATOR: 'maquette3d+configurateur',
  VIRTUAL_TOUR: 'visite_virtuelle',
})

export type PackValue = $Values<typeof PACKS>

export type ProgramPayload = {|
  programId: string,
  label: string,
  location: string,
  pack: PackValue,
  links: {
    building3d?: string,
    contact?: string,
  },
|}

export type Building3dPayload = {|
  cameraPath: string,
  compass: number,
  floorPictures: [{ floorNum: string, floorPictures: string[] }],
  lots: Lot[],
  modelizationPath: string,
|}

type Coordinate = { x: number, y: number, z: number }

type ProgramCameraPayload = {|
  aspect: number,
  cameraFOV: number,
  cameraIndexMax: number,
  imageCount: number,
  lookAt: Coordinate[],
  positions: Coordinate[],
|}

export const fetchBuilding3d = async (
  httpClient: Axios,
  building3d: string,
): Promise<Building3dPayload> => (await httpClient.get(building3d)).data

export const fetchProgram = async (
  httpClient: Axios,
  programHostname: string,
): Promise<ProgramPayload> =>
  (await httpClient.get(`/api/program?by_domain=${programHostname}`)).data

export const fetchProgramCamera = async (
  httpClient: Axios,
  programCamera: string,
): Promise<ProgramCameraPayload> => (await httpClient.get(programCamera)).data
