// @flow

import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Colors } from './../Colors'

const linkStyles = ({ accentued }) => `
  &,
  &:focus {
    text-decoration: underline;
    color: ${accentued ? Colors.green : Colors.greyRegular};
    transition: color ease-in-out 250ms;
    cursor: pointer;
  }

  &:hover {
    color: ${Colors.white};
  }
`

export const Link = styled(ReactRouterLink)(props => linkStyles(props))

export const FakeLink = styled.span(props => linkStyles(props))

export const NativeLink = styled.a(props => linkStyles(props))
