// @flow

import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoBold } from '../Typography/Fonts'

export const ModalTitle = styled.h1`
  width: 100%;
  margin: 0 15px 40px 15px;

  font-family: ${RobotoBold.fontFamily};
  font-size: 40px;
  font-weight: ${RobotoBold.weight};
  letter-spacing: normal;
  color: ${Colors.blackLight};

  @media (min-width: 542px) {
    margin-left: 0;
    margin-right: 0;
    font-size: 96px;
  }
`

export const LayoutTwoColumns = {
  Row: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    width: 100%;
  `,

  Column: styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    margin: 15px 15px 0 15px;

    @media (min-width: 542px) {
      flex: 1

      margin-top: 0;
      margin-left: 15px;
      margin-right: 0;

      &:first-child {
        margin-left: 0;
        margin-right: 15px;
      }

    }
  `,
}

export const LayoutWrapper = styled.div`
  width: 100%;
  margin: 0 15px;

  @media (min-width: 542px) {
    width: 542px;
    margin: auto;
  }

  & ${LayoutTwoColumns.Row} {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`
