// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fill="#ffffff"
      fillRule="evenodd"
      d="M10 8.513l5.205-5.205a1.052 1.052 0 0 1 1.487 1.487L11.487 10l5.205 5.205a1.052 1.052 0 0 1-1.487 1.487L10 11.487l-5.205 5.205a1.052 1.052 0 0 1-1.487-1.487L8.513 10 3.308 4.795a1.052 1.052 0 0 1 1.487-1.487L10 8.513z"
    />
  </svg>
)
