// @flow

import * as React from 'react'
import uniqid from 'uniqid'
import {
  Form,
  Input,
  Checkbox,
  Label,
  CheckboxRow,
  FieldError,
  SubmitButton,
  FormDescription,
} from './../../Guidelines/Form'
import { FakeLink, NativeLink } from './../../Guidelines/Typography/Link'
import {
  ModalTitle,
  LayoutTwoColumns,
} from './../../Guidelines/Modal/ModalContent'
import Customer from '../Customer'

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onRegistration: (
    firstName: string,
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  onRegistrationSuccess: (customer: Customer) => any,
  switchToLogInMode: () => any,
|}

type State = {|
  registrationInProgress: boolean,
  errors: OneErrorPayload[],
  uid: string,
|}

const onlyApiErrors = (errors: OneErrorPayload[]) =>
  errors.filter(oneError => oneError.field !== 'cgu')

export default class CustomerRegistrationForm extends React.Component<
  Props,
  State,
> {
  +firstNameFieldRef: { current: null | React.ElementRef<'input'> }
  +emailFieldRef: { current: null | React.ElementRef<'input'> }
  +passwordFieldRef: { current: null | React.ElementRef<'input'> }
  +cguCheckboxRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.firstNameFieldRef = React.createRef()
    this.emailFieldRef = React.createRef()
    this.passwordFieldRef = React.createRef()
    this.cguCheckboxRef = React.createRef()

    this.state = { errors: [], registrationInProgress: false, uid: uniqid() }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onRegistration, onRegistrationSuccess } = this.props

    if (
      !this.firstNameFieldRef.current ||
      !this.emailFieldRef.current ||
      !this.passwordFieldRef.current ||
      !this.cguCheckboxRef.current
    ) {
      throw new Error('Unable to access to all the fields')
    }

    const firstName = this.firstNameFieldRef.current.value
    const email = this.emailFieldRef.current.value
    const password = this.passwordFieldRef.current.value
    const cguAccepted = this.cguCheckboxRef.current.checked

    if (!cguAccepted) {
      this.setState({
        errors: [
          ...onlyApiErrors(this.state.errors),
          {
            field: 'cgu',
            message:
              "Merci d'accepter les Conditions Générales d'Utilisation pour vous inscrire",
          },
        ],
      })
      return
    } else {
      this.setState({
        errors: onlyApiErrors(this.state.errors),
      })
    }

    this.setState({ registrationInProgress: true }, async () => {
      const { customer, errors } = await onRegistration(
        firstName,
        email,
        password,
      )

      if (errors.length > 0) {
        this.setState({
          registrationInProgress: false,
          errors: errors,
        })

        return
      }

      if (!customer) {
        throw new Error(
          'No errors in registration process, but no data neither',
        )
      }

      this.setState({ registrationInProgress: false, errors: [] }, () => {
        onRegistrationSuccess(customer)
      })
    })
  }

  getErrorByField(errors: OneErrorPayload[], source: string): ?OneErrorPayload {
    return this.state.errors.find(oneError => oneError.field === source)
  }

  render() {
    const { switchToLogInMode } = this.props
    const { errors, registrationInProgress, uid } = this.state

    const firstNameError = this.getErrorByField(errors, 'firstName')
    const emailError = this.getErrorByField(errors, 'email')
    const passwordError = this.getErrorByField(errors, 'password')
    const cguError = this.getErrorByField(errors, 'cgu')

    return (
      <React.Fragment>
        <ModalTitle>Inscription</ModalTitle>

        <LayoutTwoColumns.Row>
          <LayoutTwoColumns.Column>
            <Form onSubmit={this.handleRegisterFormSubmit}>
              <Input
                ref={this.firstNameFieldRef}
                type="text"
                name="first_name"
                placeholder="Prénom"
                defaultValue={
                  process.env.NODE_ENV === 'development'
                    ? `Norbert #${uid}`
                    : null
                }
                errored={!!firstNameError}
              />

              {firstNameError ? (
                <FieldError>{firstNameError.message}</FieldError>
              ) : null}

              <Input
                ref={this.emailFieldRef}
                type="text"
                name="email"
                placeholder="Email"
                defaultValue={
                  process.env.NODE_ENV === 'development'
                    ? `norbert+${uid}@custhome.app`
                    : null
                }
                errored={!!emailError}
              />

              {emailError ? (
                <FieldError>{emailError.message}</FieldError>
              ) : null}

              <Input
                ref={this.passwordFieldRef}
                type="password"
                name="password"
                placeholder="Mot de passe"
                defaultValue={
                  process.env.NODE_ENV === 'development' ? '123456' : null
                }
                errored={!!passwordError}
              />

              {passwordError ? (
                <FieldError>{passwordError.message}</FieldError>
              ) : null}

              <CheckboxRow>
                <Checkbox
                  ref={this.cguCheckboxRef}
                  id={`cgu-checkbox-${uid}`}
                  errored={!!cguError}
                />
                <Label htmlFor={`cgu-checkbox-${uid}`}>
                  J’accepte les{' '}
                  <NativeLink
                    href="https://cdn.custhome.app/CGU_Magelan.pdf"
                    accentued
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CGU
                  </NativeLink>{' '}
                  et l’utilisation de mes données à des fins d’authentification,
                  dans le respect de la{' '}
                  <NativeLink
                    href="https://www.ogic.fr/rgpd"
                    accentued
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    politique de confidentialité
                  </NativeLink>{' '}
                  d'OGIC.
                </Label>
              </CheckboxRow>

              {cguError ? <FieldError>{cguError.message}</FieldError> : null}

              <SubmitButton
                type="submit"
                disabled={registrationInProgress}
                value="Valider"
              />
            </Form>
          </LayoutTwoColumns.Column>

          <LayoutTwoColumns.Column>
            <FormDescription>
              Pour sauvegarder votre configuration, merci d’ajouter les
              informations suivantes afin de créer votre compte. Vous pourrez
              ainsi retrouver toutes vos configurations ultérieurement.
              <br />
              <br />
              Merci de prévoir 8 caractères minimum pour votre mot de passe.
              <br />
              <br />
              <FakeLink onClick={() => switchToLogInMode()}>
                J'ai déjà un compte
              </FakeLink>
            </FormDescription>
          </LayoutTwoColumns.Column>
        </LayoutTwoColumns.Row>
      </React.Fragment>
    )
  }
}
