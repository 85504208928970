// @flow

import { type History, type Location } from 'history'

// @see https://developers.google.com/analytics/devguides/collection/analyticsjs
export type GoogleAnalyticsFunction = (
  actionType: 'config' | 'set' | 'event' | 'send',
  actionParameters: string,
  additionalConfigInfo?: any,
) => void

const noop: GoogleAnalyticsFunction = (
  actionType: 'config' | 'set' | 'event' | 'send',
  actionParameters: string,
  additionalConfigInfo?: any = {},
) => {
  console.warn(
    'You try to report the event to GA but the `gtag` function was not found. The event:',
    { actionType, actionParameters, additionalConfigInfo },
  )
}

export const useGlobalGoogleAnalyticsFunction = (): GoogleAnalyticsFunction => {
  // The ga function is defined in the index.html file
  return window.ga || noop
}

/**
 * To debug GA events, the simplier way is to install the official `Google Analytics Debugger`
 * extension:
 *
 * @see https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna
 */
export class GoogleAnalyticsService {
  +googleAnalyticsFunction: GoogleAnalyticsFunction

  constructor(googleAnalyticsFunction: GoogleAnalyticsFunction) {
    this.googleAnalyticsFunction = googleAnalyticsFunction
  }

  listenToLocationChange(history: History<any>) {
    history.listen((location: Location) => {
      const page = location.pathname + location.search + location.hash

      this.googleAnalyticsFunction('set', 'location', window.location.href)
      this.googleAnalyticsFunction('set', 'page', page)

      this.googleAnalyticsFunction('send', 'pageview')
    })
  }

  reportEvent(eventName: string, eventParameters: any) {
    this.googleAnalyticsFunction('event', eventName, eventParameters)
  }
}
