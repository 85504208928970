// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="166"
    height="238"
    viewBox="0 0 166 238"
  >
    <g
      id="SVG_404"
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-10">
        <path
          id="3dit-face"
          stroke="#8E8E8E"
          strokeWidth="4"
          d="M83,120 C34.1965695,120 25,129.196569 25,178 C25,226.800037 34.1991757,236 83,236 C131.797431,236 141,226.797431 141,178 C141,129.199176 131.800037,120 83,120 Z"
        />
        <path
          id="3dit-mouth"
          fillRule="nonzero"
          stroke="#8E8E8E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M58 157.5L72 157.5 58 157.5zM94 157.5L108 157.5 94 157.5z"
        />
        <path
          id="Sparkle01"
          fillRule="nonzero"
          stroke="#8E8E8E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M57,84 L61,84 L57,84 Z M65,97.8564065 L67,94.3923048 L65,97.8564065 Z M59.1435935,92 L62.6076952,90 L59.1435935,92 Z M73,68 L73,72 L73,68 Z M73,96 L73,100 L73,96 Z M65,70.1435935 L67,73.6076952 L65,70.1435935 Z M59.1435935,76 L62.6076952,78 L59.1435935,76 Z M89,84 L85,84 L89,84 Z M81,97.8564065 L79,94.3923048 L81,97.8564065 Z M86.8564065,92 L83.3923048,90 L86.8564065,92 Z M81,70.1435935 L79,73.6076952 L81,70.1435935 Z M86.8564065,76 L83.3923048,78 L86.8564065,76 Z"
        />
        <circle
          id="Sparkle04"
          cx="99"
          cy="100"
          r="6"
          fillRule="nonzero"
          stroke="#8E8E8E"
          strokeWidth="4"
        />
        <path
          id="404"
          fill="#3BC492"
          fillRule="nonzero"
          d="M59,19.92 C59,7.76 67,2.84217094e-14 80.92,2.84217094e-14 C94.76,2.84217094e-14 102.68,7.76 102.68,19.92 L102.68,39.12 C102.68,51.44 94.76,59.2 80.92,59.2 C67,59.2 59,51.44 59,39.12 L59,19.92 Z M89.72,19.76 C89.72,14.64 86.44,11.92 80.92,11.92 C75.24,11.92 71.96,14.64 71.96,19.76 L71.96,39.36 C71.96,44.48 75.24,47.28 80.92,47.28 C86.44,47.28 89.72,44.48 89.72,39.36 L89.72,19.76 Z"
        />
        <path
          id="Combined-Shape"
          fill="#3BC492"
          fillRule="nonzero"
          d="M48.0811258,55.384507 C50.2880795,55.384507 51,56.1746479 51,58.3295775 L51,62.3521127 C51,64.5070423 50.2880795,65.2971831 48.0811258,65.2971831 L44.0943709,65.2971831 L44.0943709,73.0549296 C44.0943709,75.2816901 43.3824503,76 41.1754967,76 L36.192053,76 C33.9850993,76 33.2731788,75.2816901 33.2731788,73.0549296 L33.2731788,65.2971831 L10.9188742,65.2971831 C8.71192053,65.2971831 8,64.5070423 8,62.3521127 L8,58.7605634 C8,56.7492958 8.14238411,56.1746479 9.2102649,54.9535211 L31.7069536,27.0830986 C33.0596026,25.4309859 33.8427152,25 35.9072848,25 L41.1754967,25 C43.3824503,25 44.0943709,25.7183099 44.0943709,27.9450704 L44.0943709,55.384507 L48.0811258,55.384507 Z M33.2731788,40.228169 L20.9569536,55.384507 L33.2731788,55.384507 L33.2731788,40.228169 Z"
          transform="rotate(-20 29.5 50.5)"
        />
        <path
          id="Combined-Shape"
          fill="#3BC492"
          fillRule="nonzero"
          d="M140.948417,69.0802251 C143.15537,69.0802251 143.867291,69.8703659 143.867291,72.0252955 L143.867291,76.0478307 C143.867291,78.2027603 143.15537,78.9929011 140.948417,78.9929011 L136.961662,78.9929011 L136.961662,86.7506476 C136.961662,88.9774082 136.249741,89.695718 134.042788,89.695718 L129.059344,89.695718 C126.85239,89.695718 126.14047,88.9774082 126.14047,86.7506476 L126.14047,78.9929011 L103.786165,78.9929011 C101.579211,78.9929011 100.867291,78.2027603 100.867291,76.0478307 L100.867291,72.4562814 C100.867291,70.4450138 101.009675,69.8703659 102.077556,68.6492392 L124.574245,40.7788166 C125.926894,39.126704 126.710006,38.695718 128.774576,38.695718 L134.042788,38.695718 C136.249741,38.695718 136.961662,39.4140279 136.961662,41.6407885 L136.961662,69.0802251 L140.948417,69.0802251 Z M126.14047,53.9238871 L113.824245,69.0802251 L126.14047,69.0802251 L126.14047,53.9238871 Z"
          transform="rotate(21 122.367 64.196)"
        />
        <path
          id="Combined-Shape"
          fill="#8E8E8E"
          fillRule="nonzero"
          d="M115.625031,19.1148257 C115.98821,18.9810725 116.148129,19.0683372 116.278962,19.4320591 L116.532187,20.136037 C116.612375,20.3589634 116.617623,20.5164781 116.570344,20.7066248 L115.06832,26.4285503 L117.939704,25.3710634 C118.302883,25.2373101 118.458581,25.3128419 118.593634,25.6882968 L118.977693,26.7559967 C119.108526,27.1197186 119.041457,27.2905791 118.678278,27.4243324 L113.298688,29.4055529 C112.946858,29.5351263 112.775591,29.4520414 112.644758,29.0883195 L112.387312,28.3726086 C112.315565,28.1731482 112.317446,27.9997207 112.368945,27.821307 L113.866749,22.0876485 L111.097509,23.1075173 C110.745679,23.2370908 110.574412,23.1540058 110.439358,22.7785509 L110.05952,21.722584 C109.928687,21.3588621 110.007105,21.1838218 110.358935,21.0542483 L115.625031,19.1148257 Z M125.067262,13 C125.526044,13 125.669413,13.1704225 125.669413,13.6507042 L125.669413,14.5802817 C125.669413,14.8746479 125.612065,15.0605634 125.483033,15.2619718 L121.511707,21.2887324 L125.138947,21.2887324 C125.597728,21.2887324 125.741097,21.443662 125.741097,21.9394366 L125.741097,23.3492958 C125.741097,23.8295775 125.597728,24 125.138947,24 L118.343248,24 C117.898803,24 117.741097,23.8295775 117.741097,23.3492958 L117.741097,22.4042254 C117.741097,22.1408451 117.812782,21.9394366 117.941814,21.7535211 L121.91314,15.7112676 L118.414932,15.7112676 C117.970488,15.7112676 117.812782,15.5408451 117.812782,15.0450704 L117.812782,13.6507042 C117.812782,13.1704225 117.970488,13 118.414932,13 L125.067262,13 Z"
        />
      </g>
    </g>
  </svg>
)
