// @flow

import { createGlobalStyle } from 'styled-components'
import pantonSemiBoldTtf from './fonts/Panton-SemiBold.ttf'
import pantonSemiBoldWoff from './fonts/Panton-SemiBold.woff'
import pantonSemiBoldWoff2 from './fonts/Panton-SemiBold.woff2'

export default createGlobalStyle`
    @font-face {
        font-family: 'Panton';
        font-weight: 600;
        font-style: normal;
        src:
            local('Panton SemiBold'),
            url('${pantonSemiBoldWoff2}') format('woff2'),
            url('${pantonSemiBoldWoff}') format('woff'),
            url('${pantonSemiBoldTtf}') format('truetype');
    }
`
