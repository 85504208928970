// @flow

export const RobotoNormal = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 500,
}

export const RobotoBold = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 700,
}

export const PantonSemiBold = {
  fontFamily: 'Panton, "Helvetica Neue", Helvetica, "Arial Narrow", sans-serif',
  weight: 600,
}

export const PatrickHand = {
  fontFamily: '"Patrick Hand", cursive',
  weight: 400,
}
