// @flow

import * as React from 'react'
import {
  Form,
  Input,
  FieldError,
  SubmitButton,
  FormDescription,
} from './../../Guidelines/Form'
import {
  ModalTitle,
  LayoutTwoColumns,
} from './../../Guidelines/Modal/ModalContent'
import { FakeLink } from './../../Guidelines/Typography/Link'

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  onRequestNewPassword: (email: string) => Promise<OneErrorPayload[]>,
  onRequestNewPasswordSuccess: () => any,
  onExitedAfterSuccess: ?() => any,
  switchToLogInMode: () => any,
|}

type State = {|
  requestNewPasswordInProgress: boolean,
  errors: OneErrorPayload[],
  newPasswordRequested: boolean,
|}

export default class RequestNewPasswordForm extends React.Component<
  Props,
  State,
> {
  +emailFieldRef: { current: null | React.ElementRef<'input'> }

  constructor(props: Props) {
    super(props)

    this.emailFieldRef = React.createRef()

    this.state = {
      errors: [],
      requestNewPasswordInProgress: false,
      newPasswordRequested: false,
    }
  }

  handleRegisterFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { onRequestNewPassword, onRequestNewPasswordSuccess } = this.props

    if (!this.emailFieldRef.current) {
      throw new Error('Unable to access to email field')
    }

    const email = this.emailFieldRef.current.value

    this.setState({ requestNewPasswordInProgress: true }, async () => {
      const errors = await onRequestNewPassword(email)

      if (errors.length > 0) {
        this.setState({
          requestNewPasswordInProgress: false,
          errors: errors,
        })

        return
      }

      this.setState(
        {
          requestNewPasswordInProgress: false,
          errors: [],
          newPasswordRequested: true,
        },
        () => {
          onRequestNewPasswordSuccess()
        },
      )
    })
  }

  getErrorByField(errors: OneErrorPayload[], source: string): ?OneErrorPayload {
    return this.state.errors.find(oneError => oneError.field === source)
  }

  render() {
    const { onExitedAfterSuccess, switchToLogInMode } = this.props
    const {
      errors,
      requestNewPasswordInProgress,
      newPasswordRequested,
    } = this.state

    const emailError = this.getErrorByField(errors, 'email')

    return (
      <React.Fragment>
        <ModalTitle>Réinitialiser</ModalTitle>

        {!newPasswordRequested ? (
          <LayoutTwoColumns.Row>
            <LayoutTwoColumns.Column>
              <Form onSubmit={this.handleRegisterFormSubmit}>
                <Input
                  ref={this.emailFieldRef}
                  type="text"
                  name="email"
                  placeholder="Votre email"
                  defaultValue={
                    process.env.NODE_ENV === 'development'
                      ? `customer@custhome.com`
                      : null
                  }
                  errored={!!emailError}
                />

                {emailError ? (
                  <FieldError>{emailError.message}</FieldError>
                ) : null}

                <SubmitButton
                  type="submit"
                  disabled={requestNewPasswordInProgress}
                  value="Valider"
                />
              </Form>
            </LayoutTwoColumns.Column>

            <LayoutTwoColumns.Column>
              <FormDescription>
                Veuillez ajouter votre email afin de reçevoir la procédure de
                réinitialisation de votre mot de passe.
                <br />
                <br />
                <FakeLink onClick={() => switchToLogInMode()}>
                  Se connecter
                </FakeLink>
              </FormDescription>
            </LayoutTwoColumns.Column>
          </LayoutTwoColumns.Row>
        ) : (
          <LayoutTwoColumns.Row>
            <LayoutTwoColumns.Column>
              <p>
                Un email vous permettant de renouveler votre mot de passe vous a
                été envoyé.
                {onExitedAfterSuccess ? (
                  <React.Fragment>
                    <br />
                    <br />
                    <FakeLink onClick={() => onExitedAfterSuccess()}>
                      Accéder à la vue du bâtiment
                    </FakeLink>
                  </React.Fragment>
                ) : null}
              </p>
            </LayoutTwoColumns.Column>
          </LayoutTwoColumns.Row>
        )}
      </React.Fragment>
    )
  }
}
