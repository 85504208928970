// @flow

import { type Axios } from 'axios'
import ClientOAuth2, { Token } from 'client-oauth2'
import ConfigurationShoppingCart from '../FlatConfigurator/MainMenu/Configuration/ShoppingCart/ConfigurationShoppingCart'
import Customer from './Customer'
import { writeToken } from './storage'
import CustomerAuthenticationExpired from './CustomerAuthenticationExpired'
import CustomerAuthenticationMissing from './CustomerAuthenticationMissing'

export type MePayload = {|
  id: string,
  firstName: string,
  lastName?: string,
  email: string,
  phone?: string,
|}

export type ConfigurationPayload = {|
  lotId: string,
  configurationId: string,
  label: string,
  floorLabel: string,
  typology: string,
  area: number,
  lotPriceIncludingTaxes20: number,
  optionPrice: number,
  available: boolean,
  status: string,
  customerHasRequestedAContact: boolean,
  plan: string,
  preview: ?string,
|}

export type ConfigurationsPayload = Array<ConfigurationPayload>

export type ConfigurationReceiptPayload = {|
  configurationId: string,
  receiptPdfUrl?: string,
  optionPrice: number,
  rooms: Array<{|
    label: string,
    price: number,
    categories: Array<{|
      label: string,
      options: Array<{|
        title: string,
        value: string,
        price: number,
      |}>,
    |}>,
  |}>,
|}

export type ShoppingCartPayload = {|
  programId: string,
  lotId: string,
  items: Array<{|
    id: string,
    value: string | boolean | null,
  |}>,
|}

type LoginOrRegistrationResult =
  | {|
      token: Token,
      me: MePayload,
    |}
  | {|
      errors: Array<{| field: ?string, message: string |}>,
    |}

const refreshToken = async (token: Token) => {
  if (!token.expired()) {
    return token
  }

  const newToken = await token.refreshToken()
  writeToken(newToken)
}

const refreshCustomerToken = async (customer: Customer): Promise<Customer> => {
  const oldToken = customer.authToken
  const newToken = await refreshToken(oldToken)
  if (newToken === oldToken) {
    return customer
  }

  return customer.refreshToken(newToken)
}

export const loginCustomer = async (
  httpClient: Axios,
  authClient: ClientOAuth2,
  login: string,
  password: string,
): Promise<LoginOrRegistrationResult> => {
  let token
  try {
    token = await authClient.owner.getToken(login, password)
  } catch (err) {
    if (err.code !== 'EAUTH') {
      throw err
    }

    return {
      errors:
        err.body && err.body.error_description
          ? [{ field: null, message: err.body.error_description }]
          : [{ field: null, message: err.toString() }],
    }
  }

  if (!token) {
    throw new Error('No login error detected but no token available neither')
  }

  const meResponse = await fetchMe(httpClient, token)

  return {
    me: meResponse.me,
    token: meResponse.token,
  }
}

export const registerCustomer = async (
  httpClient: Axios,
  authClient: ClientOAuth2,
  programId: string,
  firstName: string,
  email: string,
  password: string,
): Promise<LoginOrRegistrationResult> => {
  try {
    await httpClient.post(`/api/customer`, {
      program: programId,
      firstName,
      email,
      password,
    })
  } catch (err) {
    if (!err.response || err.response.status !== 400) {
      throw err
    }

    return Promise.resolve({
      errors: err.response.data.map(oneError => ({
        field: oneError.source,
        message: oneError.message,
      })),
    })
  }

  return loginCustomer(httpClient, authClient, email, password)
}

export const fetchMe = async (
  httpClient: Axios,
  token: Token,
): Promise<{| me: MePayload, token: Token |}> => {
  token = await refreshToken(token)
  if (!token) {
    throw new CustomerAuthenticationMissing()
  }

  try {
    const response = await httpClient.get(`/api/private/me`, token.sign({}))

    return { token, me: response.data }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const saveShoppingCart = async (
  httpClient: Axios,
  customer: Customer,
  shoppingCart: ConfigurationShoppingCart,
): Promise<{| customer: Customer, configurationId: string |}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.post(
      '/api/private/configuration',
      {
        programId: shoppingCart.programId,
        lotId: shoppingCart.lotId,
        items: shoppingCart.getItems(),
        ambianceId: shoppingCart.ambianceId,
      },
      customer.authToken.sign({}),
    )

    return { customer, configurationId: response.data.configurationId }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const fetchShoppingCartByLotId = async (
  httpClient: Axios,
  customer: Customer,
  lotId: string,
): Promise<{|
  customer: Customer,
  configurationShoppingCart?: ConfigurationShoppingCart,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.get(
      `/api/private/configuration?by_lotId=${lotId}`,
      customer.authToken.sign({}),
    )

    const shoppingCartPayload = response.data
    if (!shoppingCartPayload) {
      return { customer }
    }

    const configurationShoppingCart = new ConfigurationShoppingCart(
      shoppingCartPayload.configurationId,
      shoppingCartPayload.programId,
      shoppingCartPayload.lotId,
      shoppingCartPayload.items,
      new Date(shoppingCartPayload.createdAt),
      shoppingCartPayload.ambianceId,
    )

    return { customer, configurationShoppingCart }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const fetchConfigurations = async (
  httpClient: Axios,
  customer: Customer,
): Promise<{|
  customer: Customer,
  configurationsPayload: ConfigurationsPayload,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.get(
      `/api/private/configurations`,
      customer.authToken.sign({}),
    )

    return { customer, configurationsPayload: response.data }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const fetchConfiguration = async (
  httpClient: Axios,

  customer: Customer,

  configurationId: string,
): Promise<{|
  customer: Customer,

  configurationPayload: ConfigurationPayload,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.get(
      `/api/private/configuration/${configurationId}`,

      customer.authToken.sign({}),
    )

    return { customer, configurationPayload: response.data }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const fetchConfigurationReceipt = async (
  httpClient: Axios,
  customer: Customer,
  configurationId: string,
): Promise<{|
  customer: Customer,
  configurationReceipt: ConfigurationReceiptPayload,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.get(
      `/api/private/configurations/${configurationId}/receipt`,
      customer.authToken.sign({}),
    )

    return { customer, configurationReceipt: response.data }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const fetchConfigurationReceiptPdf = async (
  httpClient: Axios,
  customer: Customer,
  receiptPdfUrl: string,
): Promise<{|
  customer: Customer,
  configurationReceiptPdf: string,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.get(
      receiptPdfUrl,
      customer.authToken.sign({}),
    )

    return { customer, configurationReceiptPdf: response.data.content }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const deleteConfiguration = async (
  httpClient: Axios,
  customer: Customer,
  configurationId: string,
): Promise<{|
  customer: Customer,
|}> => {
  customer = await refreshCustomerToken(customer)

  try {
    await httpClient.delete(
      `/api/private/configurations/${configurationId}`,
      customer.authToken.sign({}),
    )

    return { customer }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const configurationCustomerContact = async (
  httpClient: Axios,
  customer: Customer,
  configurationId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
): Promise<Array<{| field: ?string, message: string |}>> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.post(
      `/api/private/configuration/${configurationId}/contact`,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      },
      customer.authToken.sign({}),
    )

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return Promise.resolve(
        err.response.data.map(oneError => ({
          field: oneError.source,
          message: oneError.message,
        })),
      )
    }

    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const editProfilCustomer = async (
  httpClient: Axios,
  customer: Customer,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
): Promise<Array<{| field: ?string, message: string |}>> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.put(
      `/api/private/customer/${customer.id}`,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      },
      customer.authToken.sign({}),
    )

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return Promise.resolve(
        err.response.data.map(oneError => ({
          field: oneError.source,
          message: oneError.message,
        })),
      )
    }

    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const editPasswordCustomer = async (
  httpClient: Axios,
  customer: Customer,
  oldPassword: string,
  newPassword: string,
): Promise<Array<{| field: ?string, message: string |}>> => {
  customer = await refreshCustomerToken(customer)

  try {
    const response = await httpClient.put(
      `/api/private/customer/password/${customer.id}`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      customer.authToken.sign({}),
    )

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return Promise.resolve(
        err.response.data.map(oneError => ({
          field: oneError.source,
          message: oneError.message,
        })),
      )
    }

    if (err.response && err.response.status === 401) {
      throw new CustomerAuthenticationExpired()
    }

    throw err
  }
}

export const requestNewPassword = async (
  httpClient: Axios,
  programId: string,
  email: string,
): Promise<Array<{| field: ?string, message: string |}>> => {
  try {
    await httpClient.post(`/api/password/request`, {
      program: programId,
      email,
    })
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data.map(oneError => ({
        field: oneError.source,
        message: oneError.message,
      }))
    }

    throw err
  }

  return []
}

export const resetPassword = async (
  httpClient: Axios,
  token: string,
  newPassword: string,
): Promise<Array<{| field: ?string, message: string |}>> => {
  try {
    await httpClient.post(`/api/password/reset`, {
      token,
      newPassword,
    })
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return err.response.data.map(oneError => ({
        field: oneError.source,
        message: oneError.message,
      }))
    }

    if (err.response && err.response.status === 404) {
      return [
        {
          field: 'token',
          message:
            "La demande de token n'existe pas ou est expiré. Veuillez recommencer",
        },
      ]
    }

    throw err
  }

  return []
}
