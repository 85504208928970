// @flow

import styled from 'styled-components'
import { Colors } from './Colors'
import { RobotoNormal } from './Typography/Fonts'

const BaseButton = styled.input.attrs({ type: 'button' })`
  width: 100%;
  padding: 12px;
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  line-height: 1.25;
  background-color: transparent;
  color: ${Colors.white};
  border: 2px solid ${Colors.white};
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    color: ${Colors.blackLight};
    border: 2px solid ${Colors.blackLight};
  }
`

export const Button = styled(BaseButton)`
  transition: opacity 0.25s ease-in-out;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 1;
  }
`

export const PrimaryButton = styled(BaseButton)`
  color: ${Colors.green};
  border-color: ${Colors.green};
  background-color: transparent;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${Colors.blackUltraBold};
    border-color: ${Colors.green};
    background-color: ${Colors.green};
  }

  &:disabled {
    color: ${Colors.green};
    border-color: ${Colors.green};
    background-color: transparent;
    opacity: 0.5;
  }
`
